<!--
 * @Author       : JiangChao
 * @Date         : 2022-02-19 11:51:03
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-04-02 21:35:28
 * @Description  : 綁定，免押金
-->
<template>
    <div class="linePayCallback">
        <div v-if="this.type == 'success'">
            <img :src="require('@/assets/imgs/png/tubiao-ok.png')" />
            <div style="text-align: center">綁定成功</div>
        </div>
        <div v-if="this.type == 'fail'">
            <img :src="require('@/assets/imgs/png/fail-images.png')" />
            <div style="text-align: center">綁定失敗</div>
        </div>
    </div>
</template>
<script>
import { handleErr } from "@/helpers";
import { Toast } from "vant";

export default {
    name: "LinePayCallback",
    data: () => ({
        timer: null,
        type: "",
    }),
    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
            this.$_getLinepayInfo();
        } else {
            this.$router.push("login");
        }
    },
    methods: {
        $_getLinepayInfo() {
            const transactionId = this.$route.query.transactionId;
            const orderId = this.$route.query.orderId;
            this.$_verifyCardBindStatus(transactionId, orderId);
        },
        async $_verifyCardBindStatus(transactionId, orderId) {
            try {
                const res = await this.$axios.get(
                    `linePay/saveKeyConfirm?orderId=${orderId}&transactionId=${transactionId}`
                );
                if (res.data.success) {
                    const token = window.$cookies.get("token");
                    if (token) {
                        this.$axios.defaults.headers.common["token"] = token;
                    }
                    Toast("綁定成功");
                    this.type = "success";
                    this.timer = setTimeout(() => {
                        //需要定时执行的代码
                        // window.history.go(-1);
                        this.$router.push("/map");
                    }, 3000);
                } else {
                    Toast("綁定失败");
                    this.type = "fail";
                    this.timer = setTimeout(() => {
                        //需要定时执行的代码
                        // window.history.go(-1);
                        this.$router.push("/map");
                    }, 3000);
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    destroyed() {
        clearTimeout(this.timer);
    },
};
</script>
<style lang="scss" scoped>
.linePayCallback {
    display: flex;
    justify-content: center;
}
</style>
